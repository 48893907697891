import React, { createRef, Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { animateScroll as scroll } from 'react-scroll'
import classNames from 'classnames'
import WorkPage from './WorkPage'

const SCROLL_DURATION = 600

class MainBlock extends Component {

  constructor(props) {
    super(props)
    this.top = 0
    this.bottom = 0
  }

  componentDidMount() {
    this.links = this.element.current.getElementsByTagName('a')
    for (const link of this.links) {
      link.addEventListener('click', this.customClickEvent, false)
    }
    this.calculatePosition()
    if (this.props.hasPage) this.scrollAutomatically()
  }

  componentWillUnmount() {
    for (const link of this.links) {
      link.removeEventListener('click', this.customClickEvent)
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pathname === this.props.pathname) return
    if (!this.props.hasPage && !prevProps.hasPage) return
    setTimeout(() => {
      this.calculatePosition()
      this.scrollAutomatically()
    }, 100)
  }

  customClickEvent = (e) => {
    if (!e.target.pathname.startsWith('/')) return
    e.preventDefault()
    if (this.props.pathname === e.target.pathname) {
      this.props.history.push('/')
    } else {
      this.props.history.push(e.target.pathname)
    }
  }

  scrollAutomatically = () => {
    if (this.props.pathname === '/') {
      this.scrollToHighlightBlock()
    } else {
      this.scrollToHighlightOpenedPage()
    }
  }

  scrollToHighlightOpenedPage = () => {
    scroll.scrollTo(this.top - 10, {duration: SCROLL_DURATION})
  }

  scrollToHighlightBlock = () => {
    const middle = this.top + (this.bottom - this.top) / 2
    scroll.scrollTo(middle - window.innerHeight / 2, {duration: SCROLL_DURATION})
  }

  calculatePosition = () => {
    const rect = this.element.current.getBoundingClientRect()
    this.top = rect.top + this.props.windowScroll
    this.bottom = rect.bottom + this.props.windowScroll
  }

  element = createRef();

  render () {
    const { block, windowScroll, windowHeight } = this.props
    const screenMiddlePosition = windowScroll + windowHeight / 2
    const isActive = this.props.pathname === '/' && screenMiddlePosition > this.top && screenMiddlePosition <= this.bottom
    const className = classNames('block', 'textBlock', block.blockClass, {inactive: !isActive})
    // <div style={{position: 'absolute', width: '100%', background: 'rgba(0, 0, 255, 0.1)', pointerEvents: 'none', top: this.top + 'px', height: this.bottom - this.top - 10 + 'px' }} />
    return <div className="MainBlock">
      <div
        className={className}
        ref={this.element}
        dangerouslySetInnerHTML={{__html: block.text}}
      />
      {block.page && <WorkPage page={block.page} />}
    </div>
  }
}

MainBlock.propTypes = {
  block: PropTypes.object,
  hasPage: PropTypes.bool,
  pathname: PropTypes.string,
  windowScroll: PropTypes.number,
  windowHeight: PropTypes.number
}

export default withRouter(MainBlock)
