import React, { Component } from 'react'
import { Link } from "react-router-dom";
import classNames from 'classnames'
import Swiper from './Swiper.jsx'

class WorkPage extends Component {

  constructor(props) {
    super(props)
    this.state = {canShowContent: true}
  }

  showContent () {
    this.setState({
      canShowContent: true
    })
  }

  renderBlock (block, index) {
    if (block.text !== undefined) {
      return <div className="block textBlock" key={index}
        dangerouslySetInnerHTML={{__html: block.text}}
      ></div>
    } else if (block.image !== undefined) {
      let blockClass = classNames('block imageBlock', block.blockClass)
      let imageProportion = (block.height / block.width) * 100
      let captionClass = classNames('imageCaption', block.captionClass)
      return <div className={blockClass} key={index}>
        <div className="imageWrapper" style={{paddingBottom: imageProportion + '%'}}>
          {(this.state.canShowContent) ? <img src={block.image} alt={''} /> : ''}
        </div>
        <div
          className={captionClass}
          dangerouslySetInnerHTML={{__html: (block.slideNumber || '') + (block.caption || '')}}
        ></div>
      </div>
    } else if (block.video !== undefined) {
      return <div className="block videoBlock" key={index}>
        <div
          className="videoBlockContainer"
          dangerouslySetInnerHTML={{__html: (this.state.canShowContent) ? block.video : ''}}
        ></div>
        {block.caption && <div className="imageCaption" dangerouslySetInnerHTML={{__html: block.caption}} />}
      </div>
    } else if (block.table !== undefined) {
      return <div className="block tableBlock" key={index}>
        <table><tbody>
          {block.table.map(function (tr, trIndex) {
            return <tr key={trIndex}>
              <td>{tr.date}</td>
              <td>
                {(tr.link && tr.link !== '')
                  ? <a href={tr.link} target="_blank" rel="noopener noreferrer">{tr.name}</a>
                  : tr.name
                }
                , <strong>{tr.institution}</strong>
                , {tr.location}
              </td>
            </tr>
          })}
        </tbody></table>
      </div>
    } else if (block.slides !== undefined) {
      return <div className="block slidesBlock" key={index}>
        <Swiper>
          {block.slides.map((slide, slideIndex) => {
            // const captionClass = classNames('imageCaption', slide.captionClass)
            const pad = (number) => {
              const s = '000000000000' + number
              return s.substr(s.length - 2)
            }
            slide.slideNumber = 'Slide ' + pad(slideIndex + 1) + ' of ' + pad(block.slides.length) + '. '
            return <div className="swiper-slide" key={slideIndex}>
              {this.renderBlock(slide, slideIndex)}
            </div>
          })}
        </Swiper>
      </div>
    }
  }

  render () {
    const { blocks } = this.props.page
    return <div className="WorkPage" data-path={this.props.pathname}>
      <Link className="close" to="/">✕</Link>
      {(blocks === undefined)
        ? <div className="block textBlock">Not found</div>
        : blocks.map(this.renderBlock.bind(this))
      }
      <div className="clear"></div>
    </div>
  }

}

export default WorkPage
