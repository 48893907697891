import React, { Component } from 'react'
import PropTypes from 'prop-types'
import MainBlock from './MainBlock.jsx'


class MainBlocks extends Component {

  constructor(props) {
    super(props)
    this.state = {
      windowScroll: 0,
      windowHeight: 0
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.getWindowParameters)
    window.addEventListener('resize', this.getWindowParameters, true);
    this.getWindowParameters()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.getWindowParameters)
    window.removeEventListener('resize', this.getWindowParameters);
  }

  getWindowParameters = () => {
    const windowScroll = window.pageYOffset
    const windowHeight = window.innerHeight
    this.setState({windowScroll, windowHeight})
  }

  render () {
    const { blocks, pages, pathname } = this.props

    // <div style={{position: 'absolute', width: '100%', height: '1px', background: 'red', top: `calc(50vh + ${this.state.windowScroll}px)`}} />
    return <div className="MainBlocks">
      {blocks.map((block, index) => {
        const hasPage = block.text.includes(`'${pathname}'`)
        block.page = hasPage ? pages[pathname] : null
        return <MainBlock
          key={index}
          block={block}
          hasPage={hasPage}
          pathname={pathname}
          windowScroll={this.state.windowScroll}
          windowHeight={this.state.windowHeight}
        />
      })}
    </div>
  }

}

MainBlocks.propTypes = {
  blocks: PropTypes.array,
  pages: PropTypes.object,
  pathname: PropTypes.string
}

export default MainBlocks
