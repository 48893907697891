import Data from '../i18n/en.json'
import Typograf from 'typograf'

const processedData = (function () {
  const tp = new Typograf({locale: ['en-US']})
  tp.disableRule('en-US/dash/main');

  const iterate = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map(value => {
        if (typeof value === 'object') {
          return iterate(value)
        } else {
          return tp.execute(value)
        }
      })
    } else {
      return Object.assign(...Object.keys(obj).map(key =>
        (typeof obj[key] === 'object')
          ? { [key]: iterate(obj[key]) }
          : { [key]: tp.execute(obj[key]) }
      ))
    }
  }

  // console.log('obj', iterate(Data).pages['/'].blocks)
  return iterate(Data)
})()

export default processedData
