import React, { createRef, Component } from 'react'
import SwiperPlugin from 'iso-swiper'

class Swiper extends Component {

  componentDidMount () {
    const domElement = this.swiper.current
    this.swipe = new SwiperPlugin()(domElement, {
      // pagination: '.swiper-pagination',
      // paginationType: 'fraction',
      // paginationClickable: true,
      grabCursor: true,
      slidesPerView: 1,
      centeredSlides: true
    })
    this.swipe.on('onTap', swiper => {
      (swiper.isEnd)
        ? swiper.slideTo(0, 500)
        : swiper.slideNext()
    })
  }

  swiper = createRef();

  render () {
    return <div ref={this.swiper} className="swiper-container">
      <div className="swiper-wrapper">
        {this.props.children}
      </div>
      <div className="swiper-pagination"></div>
    </div>
  }

}

export default Swiper
