import React, { Component } from 'react'
import PropTypes from 'prop-types'
import MainBlocks from './MainBlocks'
import data from '../utils/processedData'

class MainPage extends Component {
  render () {
    const pathname = this.props.location.pathname
    const blocks = data.pages['/'].blocks

    return <div className="MainPage">
      <MainBlocks pathname={pathname} blocks={blocks} pages={data.pages} />
    </div>
  }
}

MainPage.propTypes = {
  location: PropTypes.object
}

export default MainPage
