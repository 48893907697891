import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import MainPage from './components/MainPage'
import './styles/compiled.css'

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Route path="/:path?" component={MainPage} />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)
